@if(!hideFooter)
{
<div>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer-py-60">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 mb-0 mb-md-4 pb-0 pb-md-2 text-center">
                                <a href="javascript:void(0)" class="logo-footer">
                                    <img src="assets/images/logos/mag/logo-light.png" height="55" alt="">
                                </a>
                                <p class="mt-4">{{ general.p_1 }} {{ general.tic_mag }}{{ footer.footer_p }}</p>
                                <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                    <!-- <li class="list-inline-item ms-1"><a routerLink="{{ company.url }}" href="javascript:void(0)" class="rounded"> -->
                                    <li class="list-inline-item ms-1"><a
                                            href="https://www.facebook.com/ticdesarrollomag/?locale=es_LA"
                                            target="_blank" class="rounded">
                                            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                        </a></li>
                                    <!-- <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded"> -->
                                    <li class="list-inline-item ms-1"><a
                                            href="https://www.instagram.com/desarrollomag/" 
                                            target="_blank" class="rounded">
                                            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                        </a></li>
                                </ul>
                                <!--end icon-->
                            </div>
                            <!--end col-->

                            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center">
                                <h5 class="footer-head">{{ general.company }}</h5>
                                <ul class="list-unstyled footer-list mt-4">
                                    @for(company of companys; track company){
                                    <li><a routerLink="{{ company.url }}" class="text-foot"><i
                                                class="uil uil-angle-right-b me-1"></i>
                                            {{ company.title }}</a></li>
                                    }
                                </ul>
                            </div>
                            <!--end col-->

                            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center">
                                <h5 class="footer-head">{{ footer.usefull }}</h5>
                                <ul class="list-unstyled footer-list mt-4">
                                    <!-- <li><a href="javascript:void(0)" class="text-foot"><i -->
                                    <li>@if(privacy == 'es'){
                                            <a class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                                            {{ politica.title }}</a>
                                        }
                                        @if(privacy == 'en'){<a href="javascript:void(0)" class="text-foot"><i
                                                class="uil uil-angle-right-b me-1"></i>
                                            {{ policy.title }}</a>}
                                    </li>
                                    @for(usefull of usefulls; track $index){
                                    <li><a routerLink="{{ usefull.url }}" class="text-foot"><i
                                                class="uil uil-angle-right-b me-1"></i>
                                            {{ usefull.title }}</a></li>
                                    }
                                </ul>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                </div>
            </div>
        </div>
        <!--end container-->
        <div class="footer-py-30 footer-bar">
            <div class="container text-center">
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <div class="text-sm-start">
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
        <!--end footer-->
    </footer>
</div>
}