import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {
  showMore: boolean = false;
  toggleMore() {
    this.showMore = !this.showMore;
  }

  trackByIndex(index: number, item: any) {
    return index;
  }

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  companys: any[] = [];
  usefulls: any[] = [];
  politica: any =
  {
    "title" : "Políticas de Privacidad",
    "url": "/page-privacy"
  };
  policy: any =
  {
    "title": "Privacy Policy",
    "url": "/page-privacy"
  };
  newsForm!: UntypedFormGroup;
  submitted = false;
  privacy: any = [];

  //Get Year
  year = new Date().getFullYear()
  
  footer: any = {};
  general: any = {};

  constructor(private languageService: LanguageService
  ) { }

private scrollPosition: number = 0;
  ngOnInit(): void {
    this.loadTranslations();
    this.privacy = this.languageService.getCurrentLanguage();
    this.languageService.onLanguageChanged().subscribe(language => {
      this.privacy = language;
    });
    this.languageService.onLanguageChanged().subscribe(() => {
      this.scrollPosition = window.scrollY;
      this.loadTranslations();
      setTimeout(() => window.scrollTo(0, this.scrollPosition), 0); // Restaurar la posición del scroll
    });
  }

  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.footer = data.footer;
      this.general = data.general;
      // Asigna los valores del JSON al array 
      this.companys = data.company; 
      this.usefulls = data.usefull; 
      this.privacy = data.privacy;
    });
  }
  
  changeLanguage(language: string){
  this.languageService.setLanguage(language);
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
    });
  }
}
