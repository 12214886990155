import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'c-converter',
  templateUrl: './converter.component.html',
  styleUrls: ['./converter.component.css']
})
export class ConverterComponent implements OnInit {
  
  Menuoption = 'center';

  private scrollPosition: number = 0;
  inputValue: string = '';
  selectedBaseFrom: string = '';
  decimalValue: string = '';
  binaryValue: string = '';
  conversionLogs: string = '';  
  value_1: string = '';
  value_2: string = '';
  value_3: string = '';
  valor_1: string = "";
  valor_2: string = "";
  valor_3: string = ""; 
  deci: string = ""; 
  binary: string = ""; 
  hexa: string = ""; 
  octa: string = "";

  conversor: any =[];
  decimal: any [];

  constructor(private languageService: LanguageService,
  ) { }
  
  ngOnInit() {
    this.loadTranslations();
    this.languageService.onLanguageChanged().subscribe(() => {
      this.scrollPosition = window.scrollY;
      this.loadTranslations();
      this.selectInfo();
      setTimeout(() => window.scrollTo(0, this.scrollPosition), 0);
    });
  }

  selectInfo(){
    this.languageService.getTranslations().subscribe(data => {
      if(this.selectedBaseFrom == "decimal")
        this.decimal = data.decimal;
      else if(this.selectedBaseFrom == "binary")
        this.decimal = data.binario;
      else if(this.selectedBaseFrom == "octal")
        this.decimal = data.octal;
      else if(this.selectedBaseFrom == "hexa")
        this.decimal = data.hexadecimal;
    });
  }

  loadTranslations() {
    this.languageService.getTranslations().subscribe(data => {
      this.conversor = data.conversor;
    });
  }

  changeLanguage(language: string){
  this.languageService.setLanguage(language);
    this.languageService.onLanguageChanged().subscribe(() => {
      this.loadTranslations();
      this.selectInfo();
    });
  }
  
  convert() {
    this.conversionLogs = '';

    switch (this.selectedBaseFrom) {
      case 'decimal':
        this.decimalBinario();
        this.decimalHexa();
        this.decimalOctal();

        this.valor_1 = this.conversor.binary;
        this.valor_2 = this.conversor.hexa;
        this.valor_3 = this.conversor.octa;
        break;
      case 'binary':
        this.binarioDecimal();
        this.binarioHexa();
        this.binarioOctal();
        this.valor_1 = this.conversor.deci;
        this.valor_2 = this.conversor.hexa;
        this.valor_3 = this.conversor.octa;
        break;
      case 'hexa':
        this.hexaDecimal();
        this.hexaBinario();
        this.hexaOctal();
        this.valor_1 = this.conversor.deci;
        this.valor_2 = this.conversor.binary;
        this.valor_3 = this.conversor.octa;
        break;
      case 'octal':
        this.octalDecimal();
        this.octalBinario();
        this.octalHexa();
        this.valor_1 = this.conversor.deci;
        this.valor_2 = this.conversor.binary;
        this.valor_3 = this.conversor.hexa;
        break;
      default:
        this.conversionLogs = 'Selecciona una base válida.';
        break;
    }
  }

// Decimal
  decimalBinario() {
    const decimal = parseInt(this.inputValue, 10);
      this.value_1 = decimal.toString(2);
  }

  decimalHexa() {
    const decimal = parseInt(this.inputValue, 10);
    this.value_2 = decimal.toString(16).toUpperCase();
  }

  decimalOctal() {
    const decimal = parseInt(this.inputValue, 10);
    this.value_3 = decimal.toString(8);
  }

//Binario
  binarioDecimal() {
    if (!this.inputValue || !/^[01]+$/.test(this.inputValue)) {
      this.inputValue += `Error: Por favor, ingrese un número binario válido.\n`;
      return;
    }
    
    const binary  = parseInt(this.inputValue, 2);
    this.value_1 = binary.toString(10);
  }

  binarioHexa() {
    const binary = parseInt(this.inputValue, 2);
    this.value_2 = binary.toString(16).toUpperCase();
  }

  binarioOctal() {
    const binary = parseInt(this.inputValue, 2);
    this.value_3 = binary.toString(8);
  }

//Hexadecimal
  hexaDecimal() {
    const hexa = parseInt(this.inputValue, 16);
    this.value_1 = hexa.toString(10);
  }

  hexaBinario() {
    const hexa = parseInt(this.inputValue, 16);
    this.value_2  = hexa.toString(2);
    console.log(this.binaryValue);
  }

  hexaOctal() {
    const hexa = parseInt(this.inputValue, 16);
    this.value_3 = hexa.toString(8);
  }

//Octal
  octalDecimal() {
    const octal = parseInt(this.inputValue, 8);
    this.value_1 = octal.toString(10);
  }

  octalBinario() {
    const octal = parseInt(this.inputValue, 8);
    this.value_2  = octal.toString(2);
  }

  octalHexa() {
    const octal = parseInt(this.inputValue, 8);
    this.value_3 = octal.toString(16).toUpperCase();
  }
}
