// const url = 'https://localhost:7093/'; 
// const url = 'http://localhost:8000/'; 
// const url = 'http://localhost/apiMAG/public/'; 
const url = 'https://api.mag.ticdesarrollomag.com/public/'; 

export const environment = {
  production: false,
	urlBase: url,
	url: url + 'api/',
	idle: {
		start: 300,
		timeout: 60,
	},
};
