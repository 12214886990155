import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';

import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { ConverterComponent } from './core/components/converter/converter.component';
import { IframesComponent } from './core/components/iframes/iframes.component';
import { PageContacPromoComponent } from './core/components/page-contac-promo/page-contac-promo.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      /*  Magos  */
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      { path: 'about-us', component: PageAboutusTwoComponent },
      { path: 'contact-us', component: PageContactOneComponent },
      { path: 'promo', component: PageContacPromoComponent },

      { path: 'page-privacy', component: PagePrivacyComponent },
      { path: 'iframes', component: IframesComponent },
      { path: 'conversor', component: ConverterComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, { 
      scrollPositionRestoration: "top",
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: "enabled",
      useHash: true
      }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
