import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const email = control.value;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!email || email.length > 254 || !emailRegex.test(email)) {
    return { invalidEmail: true }; 
  }

  const [localPart, domainPart] = email.split('@');
  
  if (localPart.length > 64 || domainPart.length > 255 || domainPart.startsWith('-') || domainPart.endsWith('-') || /\.{2,}/.test(domainPart)) {
    return { invalidEmail: true }; 
  }

  return null; 
}
