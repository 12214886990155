<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100 d-print-none" id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="pages-heading">
                    <h1 class="title"> {{ conversor.h1 }} </h1>
                    <!-- <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 23th Sep,
              2021</li>
          </ul> -->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="position-breadcrumb">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ conversor.ul }}</a></li>
                </ul>
            </nav>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-color-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card-body">
                    <h2>{{ conversor.h2 }}</h2>
                    <p class="text-muted">{{ conversor.h3p1 }}</p>
                    <p class="text-muted">{{ conversor.h3p2 }}</p>
                </div>

            </div>
            <div class="row justify-content-center">
                <!-- Forms Start -->
                <div class="col-md-6 " id="forms">
                    <div class="component-wrapper rounded shadow">
                        <div class="p-4 border-bottom">
                            <h4 class="title mb-0"> Forms </h4>
                        </div>

                        <div class="p-4">
                            <form>
                                <div class="row">
                                    <!-- Resto del formulario -->

                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label">De: </label>
                                            <div class="mb-0">
                                                <select id="inputOf" class="form-select form-control" placeholder="Seleccionar" [(ngModel)]="selectedBaseFrom" [ngModelOptions]="{standalone: true}" (change)="selectInfo()">
                          <option value="decimal">Decimal</option>
                          <option value="binary">Binario</option>
                          <option value="hexa">Hexadecimal</option>
                          <option value="octal">Octal</option>
                        </select>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Resto de las opciones de entrada -->

                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label">Ingrese el valor</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="value" id="value" class="form-control ps-5" placeholder="Valor :" [(ngModel)]="inputValue" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Resultados de las conversiones -->
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ valor_1 }}</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="resultBinary" id="resultBinary" class="form-control ps-5" [value]="value_1" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ valor_2 }}</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="resultHexa" id="resultHexa" class="form-control ps-5" [value]="value_2" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label class="form-label">{{ valor_3 }}</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="resultOctal" id="resultOctal" class="form-control ps-5" [value]="value_3" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12">
                                        <button type="button" id="converter" name="converter" class="btn btn-primary" (click)="convert()">
                      Convertir </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="card-body">
                </div>

                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        @for(decimals of decimal; track $index ){
                        <h5 class="card-title">{{ decimals.title }}:</h5>
                        <p class="text-muted">{{ decimals.p_1 }}
                        </p>
                        <P class="text-muted">{{ decimals.p_2 }}:</P>
                        <ul class="list-unstyled text-muted">
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_1}}
                            </li>
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_2 }}
                            </li>
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_3 }}
                            </li>
                            @if(decimals.li_4){
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_4 }}
                            </li>
                            } @if(decimals.li_5){
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_5 }}
                            </li>
                            }
                        </ul>
                        @if(decimals.p_3){
                        <p class="text-muted">{{ decimals.p_3 }}:</p>
                        } @if(decimals.li_6 || decimals.li_7 ){
                        <ul class="list-unstyled text-muted">
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_6 }}
                            </li>
                            <li>
                                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                                {{ decimals.li_7 }}
                            </li>
                        </ul>
                        } }

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->