<!-- Start Contact -->
<section class="section pb-0">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">{{ contact.promo }}!</h4>
            <div class="custom-form mt-3">

              <form [formGroup]="form" #contactForm="ngForm" class="form" (ngSubmit)="onSubmit(contactForm)">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{ contact.name }} <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input formControlName="name" name="name" id="name" type="text" class="form-control ps-5"
                          placeholder="{{ contact.name }} :">
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{ contact.email }} <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input formControlName="email" name="email" id="email" type="email" class="form-control ps-5"
                          placeholder="{{ contact.email }} :">
                        <div *ngIf="form.controls['email'].touched">
                          @if(currentLanguage == 'es'){
                            <div class="alert alert-danger-custom"
                              *ngIf="!form.controls['email'].hasError('required') && form.controls['email'].hasError('invalidEmail')">
                              Formato de email invalido.
                            </div>
                          }
                          @if(currentLanguage !== 'es'){
                            <div class="alert alert-danger-custom"
                              *ngIf="!form.controls['email'].hasError('required') && form.controls['email'].hasError('invalidEmail')">
                              Enter a valid email.
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{ contact.subject }}</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input formControlName="subject" name="subject" id="subject" class="form-control ps-5"
                          placeholder="{{ contact.subject }} :">
                      </div>
                    </div>
                  </div> -->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{ contact.message }} <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea formControlName="message" name="message" id="message" rows="4"
                          class="form-control ps-5" placeholder="{{ contact.message }} :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary" [disabled]="isLoading">
                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                        {{ contact.send_message }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="card border-0">
          <div class="card-body p-0">
            <img src="assets/images/promos/promo2025.jpeg" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 mb-0 mb-md-4 pb-0 pb-md-2 text-center">
      <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
        <li class="list-inline-item ms-1">
          <a href="https://www.facebook.com/ticdesarrollomag/?locale=es_LA" target="_blank" class="rounded">
            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
          </a>
        </li>
        <li class="list-inline-item ms-1">
          <a href="https://www.instagram.com/desarrollomag/" target="_blank" class="rounded">
            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
          </a>
        </li>
        <!-- <li class="list-inline-item ms-1">
      <a href="https://www.tiktok.com/@ticdesarrollomag" target="_blank" class="rounded">
        <i-feather name="tiktok" class="fea icon-sm fea-social"></i-feather>
      </a>
    </li> -->
      </ul>
    </div>


    <p>
      <button class="btn btn-danger" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
        aria-expanded="false" aria-controls="collapseExample">
        {{ contact.condicions }}
      </button>
    </p>
    <div class="collapse" id="collapseExample">
      <div class="card card-body">
        <h2 class="card-title">Condiciones del Sorteo</h2>
        <div class="accordion" id="accordionExample">
          <!-- Premios -->
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingPremios">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePremios"
                aria-expanded="true" aria-controls="collapsePremios">
                Premios
              </button>
            </h3>
            <div id="collapsePremios" class="accordion-collapse collapse show" aria-labelledby="headingPremios"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <h4 class="card-title">2 Páginas Web Gratis</h4>
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Páginas Web:</strong>
                    <ul>
                      <li>Los ganadores recibirán una página web diseñada y desarrollada en WordPress.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Una sola vista:</strong>
                    <ul>
                      <li>Cada página web contendrá una única vista, ideal para presentaciones, portafolios, landing
                        pages, etc.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Incluye un plugin:</strong>
                    <ul>
                      <li>Se integrará un plugin de tu elección para mejorar la funcionalidad de la página web.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Una edición disponible:</strong>
                    <ul>
                      <li>Tendrás la oportunidad de solicitar una edición después de la entrega inicial para ajustar
                        detalles y mejoras.</li>
                    </ul>
                  </li>
                </ul>

                <h4 class="card-title">50% de Descuento para 10 Páginas Web Básicas</h4>
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Descuento en los primeros dos paquetes:</strong>
                    <ul>
                      <li>El descuento será aplicable a nuestros paquetes básicos y estándar de diseño web en WordPress.
                      </li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>El costo se acordará con el participante ganador:</strong>
                    <ul>
                      <li>Nos pondremos en contacto con los ganadores para discutir y acordar el costo final según sus
                        necesidades específicas.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <!-- Requisitos de Participación -->
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingRequisitos">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseRequisitos" aria-expanded="false" aria-controls="collapseRequisitos">
                Requisitos de Participación
              </button>
            </h3>
            <div id="collapseRequisitos" class="accordion-collapse collapse" aria-labelledby="headingRequisitos"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Seguir las Redes Sociales de TicDesarrolloMag:</strong>
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/ticdesarrollomag/?locale=es_LA" target="_blank"
                          class="rounded">Facebook</a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/desarrollomag/" target="_blank" class="rounded">Instagram</a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@ticdesarrollomag" target="_blank" class="rounded">TikTok</a>
                      </li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Interacción en Redes Sociales:</strong>
                    <ul>
                      <li>Haber compartido al menos una publicación: Comparte cualquier publicación de nuestras redes
                        sociales en tu propio perfil para difundir nuestro trabajo.</li>
                      <li>Haber comentado en una publicación: Deja un comentario en cualquier publicación para
                        interactuar con nuestra comunidad.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Llenar el Formulario de Contacto:</strong> Completa el formulario de contacto disponible en
                    la página de TicDesarrolloMag.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- info adicional -->
          <div class="accordion-item">
            <h3 class="accordion-header" id="headingInfo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseInfo" aria-expanded="false" aria-controls="collapseInfo">
                Información Adicional
              </button>
            </h3>
            <div id="collapseInfo" class="accordion-collapse collapse" aria-labelledby="headingInfo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="list-unstyled text-muted">
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Válido para toda América:</strong>
                    <ul>
                      <li>El sorteo está abierto a participantes de toda América.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Dominio:</strong>
                    <ul>
                      <li>A cargo del ganador: Los ganadores deben proporcionar su propio dominio.</li>
                      <li>En caso contrario, se proporcionará un subdominio por TicDesarrolloMag: Si no tienes un
                        dominio,
                        te proporcionaremos un subdominio bajo ticdesarrollomag.com.</li>
                    </ul>
                  </li><br>
                  <li>
                    <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                    <strong>Reclamación del Premio:</strong>
                    <ul>
                      <li>Plazo para reclamar el premio: Los ganadores tendrán 48 horas para reclamar su premio después
                        de
                        haber sido notificados.</li>
                      <li>Sí no se realiza el reclamo dentro de este plazo, el premio no se otorgará: ¡Asegúrate de
                        responder a tiempo para no perder tu premio!</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->